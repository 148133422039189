.enter-account-page {
  position: relative;
}

.enter-account-wrapper {
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
  background-color: #e6edff;
  background-image: url(../../../shared/assets/BG5.webp);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1;

  .center-wrapper-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 440px;
    width: 90%;

    .emoji-icon-error {
      width: 100px;
    }
  }
}

.blur-background {
  filter: blur(2px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
}