@import "../../../app//style/colors.scss";

.account-header {
  position: absolute;
  width: 100%;
  max-width: 480px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  .account-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    position: relative;
    color: $appBlack;
  }
  .account-flex::after {
    width: 100%;
    height: 1px;
    background-color: $white;
    content: "";
    bottom: 0;
    left: 0;
    position: absolute;
  }
  .menu-icon-background {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.459);
    border-radius: 200px;
  }

  .header-blur-background {
    position: fixed;
    width: 100%;
    top: 0;
    max-width: 480px;
    left: 50%;
    transform: translateX(-50%);
    backdrop-filter: blur(3px);
    z-index: 10;

    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease all;
    background-color: rgba(0, 0, 0, 0.2);
    color: $appBlack;
  }

  .header-background {
    visibility: none;
    z-index: 1;
  }
  .header-background.isActive {
    visibility: visible;
  }
  .header-blur-background.isActive {
    opacity: 1;
    visibility: visible;
    height: 100%;
  }
}

.account-header.isActive {
  position: fixed;
  height: 100%;
}
.no-scroll {
  overflow: hidden;
}
