@import "../../../app/style/colors.scss";

.analytics-wrapper {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  background-color: $white;
  position: relative;

  .analytics-padding {
    padding: 160px 16px 16px 16px;
    .analytic-widget-title-color {
      color: $black;
    }
  }
  .upper-gradient {
    z-index: 0;
    background: linear-gradient(#e4c9ff, #ffffff);
    width: 100%;
    height: 400px;
    position: absolute;
  }
}
