@import "../../../../app/style/colors.scss";

.default-button-item {
  max-width: 100%;
  width: 343px;
  height: 56px;
  background-color: $purple;
  border-radius: 10px;
  color: $white;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s ease;
  position: relative;
  &:disabled {
    background-color: $grayLight;
    cursor: not-allowed;
  }
  &:disabled:hover {
    background-color: $grayLight;
    cursor: not-allowed;
  }
  svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.default-button-item:hover {
  background-color: $hoverPurple;
}
