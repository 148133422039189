@import "../../../app/style/colors.scss";

.avatar-wrapper {
  padding-top: 120px;
  position: relative;
  text-align: center;
  color: $appBlack;
  img {
    border-radius: 100px;
    width: 100px;
  }
  .title14-regular-urbanist {
    color: $appTextColor;
  }
}
