@import "../../../app/style/colors.scss";

.insight-wrapper-block {
  padding: 16px;
  background-color: $appCard;
  border-radius: 16px;
  .icon-wrapper {
    color: $white;
    background-color: $appBlue;
    border-radius: 100px;
    display: flex;
    position: relative;
    padding: 6px;
    .title12-medium-urbanist {
      padding-right: 2px;
      margin-left: 8px;
    }
    align-items: center;

    svg {
      height: 16px;
      // position: absolute;
      // left: 50%;
      // top: 50%;
      // transform: translate(-50%, -50%);
    }
  }
  .icon-element {
    display: flex;
  }
  .text-element {
    margin-top: 8px;
    color: $black;
    &:last-child {
      color: $appTextColor;
    }
  }
}
