@import "../../../app/style/colors.scss";

.account-information-page {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  background-color: $white;
  // height: 100%;
  position: relative;

  .upper-gradient {
    z-index: 0;
    background: linear-gradient(#e4c9ff, #ffffff);
    width: 100%;
    height: 400px;
    position: absolute;
  }

  .header-blur-background {
    position: fixed;
    width: 100%;
    top: 0;
    max-width: 480px;
    left: 50%;
    transform: translateX(-50%);
    backdrop-filter: blur(3px);
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease all;
  }
  .header-blur-background.isActive {
    opacity: 1;
    visibility: visible;
  }
  .center-block-wrapper {
    padding-bottom: 16px;
  }
  .center-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 480px;
    width: 100%;
    .loader {
      position: relative;
    }
  }
  .center-wrapper-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 440px;

    width: 90%;
    .emoji-icon-error{
      width: 100px;
    }
    .loader {
      position: relative;
    }
    .title24-bold-urbanist {
      margin-bottom: 10px;
    }
  }
  .center-wrapper-private {
    height: 100vh;
  }
}
