@import "../../../../app/style/colors.scss";

.next-update-section {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  right: 0px;
  bottom: 0px;
  color: $white;
  z-index: 1;
  width: 100%;
  max-width: 480px;
  .next-update-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: $appBlue;
    padding: 20px 16px;
    box-sizing: border-box;
    gap: 10px;

    .next-update-text {
      margin-bottom: 8px;
    }
    .next-update-iconbg {
      padding: 16px 18px;
      background-color: $white;
      border-radius: 71px;
    }
  }
}
