@import "../../../app//style/colors.scss";

.account-upper-statistic {
  width: 90%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  .flex-statistics {
    display: flex;
    justify-content: space-between;
    //z-index: 10;
    position: relative;
    margin-top: 30px;
  }
}
