@import "../../../../app/style/colors.scss";

.view-insights-section {
  width: 100%;
  color: $appBlack;
  position: relative;
  text-align: center;
  margin-top: 50px;
  .view-insights-block {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    background-color: $appCard;
    border-radius: 24px;
    .view-insights-text {
      margin-top: 8px;
      margin-bottom: 24px;
      color: $black;
    }
    .view-insights-block-imgbg {
      border-radius: 71px;
      background-color: $white;
      padding: 17px;
    }
  }
}
