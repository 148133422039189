@import "../../../../app//style/colors.scss";

.info-blocks {
  .title24-medium-urbanist {
    margin-bottom: 20px;
    color: $black;
  }
  .insight-wrapper-block + .insight-wrapper-block {
    margin-top: 20px;
  }
}
