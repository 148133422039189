@import "../../../../app//style/colors.scss";

.login-section {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;

  .login-block {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    padding: 30px 16px;
    color: $appTextColor;
  }
  .title32-semibold-urbanist {
    color: $black;
  }
}
