@import "../../../../app//style/colors.scss";

.analysing-profile-section {
  position: relative;
  width: 100%;
  z-index: 2;

  .analysing-profile-block {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    padding: 30px 16px;
    color: $black;

    .text-with-dots {
      display: flex;
      align-items: center;
      flex-direction: column;
      color: $appBlue;
      gap: 8px;

      .loader-dots {
        width: 30px;
        aspect-ratio: 2;
        --_g: no-repeat radial-gradient(circle closest-side, #315df4 90%, #0000);
        background:
          var(--_g) 0% 50%,
          var(--_g) 50% 50%,
          var(--_g) 100% 50%;
        background-size: calc(100% / 3) 50%;
        animation: dots 1s infinite linear;
      }
      @keyframes dots {
        20% {
          background-position:
            0% 0%,
            50% 50%,
            100% 50%;
        }
        40% {
          background-position:
            0% 100%,
            50% 0%,
            100% 50%;
        }
        60% {
          background-position:
            0% 50%,
            50% 100%,
            100% 0%;
        }
        80% {
          background-position:
            0% 50%,
            50% 50%,
            100% 100%;
        }
      }
    }

    .analysing-profile-clock {
      max-width: 100%;
      margin-top: 40px;
      background-color: #eef1ff;
      border: 1px solid #c8d4fd;
      border-radius: 10px;
      width: 343px;
      box-sizing: border-box;
      padding: 12px 29px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .analysing-profile-img {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $appBlue;
        border-radius: 169px;
        width: 28px;
        height: 28px;
      }

      .clock-text {
        margin-top: 10px;
      }
      .clock-text-second {
        color: $appBlue;
      }
    }
  }
}
