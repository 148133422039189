@import "../../../app//style/colors.scss";

.account-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  background-color: $white;

  img {
    margin-left: auto;
    margin-right: auto;
  }
  .flex-footer-menu {
    display: flex;
    gap: 32px;
    margin-top: 40px;
    position: relative;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    a {
      text-decoration: none;
      color: black;
    }
  }
  .flex-footer-menu + .flex-footer-menu {
    margin-bottom: 12px;
  }
  .flex-footer-menu::after {
    width: 80%;
    height: 1px;
    background-color: #0e0f0f29;
    content: "";
    top: -20px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}

.with-next-update {
  padding-bottom: 100px;
}

.null-margin {
  padding-bottom: 0px !important;
}
