.login-page {
  position: relative;
}

.login-wrapper {
  max-width: 480px;
  text-align: center;
  background-color: #e6edff;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: flex-end;
}

.blur-background {
  filter: blur(2px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.background-pic-login {
  background-image: url("../../../shared/assets/loginBG.webp");
  background-position: center top;
  background-size: 100%;
  background-repeat: no-repeat;
}
.background-pic-restore {
  background-image: url("../../../shared/assets/BG5.webp");
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
