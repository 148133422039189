@import "../../../../app//style/colors.scss";

.restore-password-section {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  max-width: 480px;
  height: 100%;
  top: 0%;
  position: absolute;

  .restore-password-block {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    padding: 30px 16px;
    color: $appTextColor;
    .restore-password-backicon-block {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 16px;
    }
  }
}
